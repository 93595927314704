import { fabric } from "fabric";
import { convertAlphaToMaskAndDilate } from "../BaseEditor/canvasUtils.ts";

export const setDrawingCursor = ({
  fabricCanvas,
  brushSize,
  brushColor,
}: {
  fabricCanvas: fabric.Canvas;
  brushSize: number;
  brushColor: string;
}) => {
  const radius = brushSize / 2;
  const brushHead = getBrushHead({ brushSize, brushColor, radius });

  fabricCanvas.freeDrawingCursor = `url('data:image/svg+xml;base64,${btoa(
    brushHead,
  )}') ${radius + 4} ${radius + 4}, crosshair`;
};

const getBrushHead = ({
  brushSize,
  brushColor,
  radius,
}: {
  brushSize: number;
  brushColor: string;
  radius: number;
}) => `
    <svg
      width="${brushSize + 4}px"
      height="${brushSize + 4}px"
      viewBox="0 0 ${brushSize + 4} ${brushSize + 4}"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="50%"
        cy="50%"
        r="${radius}"
        stroke="black"
        stroke-width="2"
        fill="none"
      />
      <circle
        cx="50%"
        cy="50%"
        r="${radius - 2}"
        stroke="${brushColor}"
        stroke-width="2"
        fill="none"
      />
    </svg>
  `;
export const addImage = ({
  fabricCanvas,
  url,
  callback,
}: {
  fabricCanvas: fabric.Canvas;
  url: string;
  callback: () => void;
}) => {
  fabric.Image.fromURL(url, (image) => {
    const scale =
      ((image.width && image.height && fabricCanvas.width && fabricCanvas.height
        ? Math.min(
            fabricCanvas.width / image.width,
            fabricCanvas.height / image.height,
          )
        : 1) *
        2) /
      3;
    image.set({
      scaleX: scale,
      scaleY: scale,
      originX: "center",
      originY: "center",
      top: (fabricCanvas.height ?? 0) / 2,
      left: (fabricCanvas.width ?? 0) / 2,
    });
    fabricCanvas.add(image);
    callback();
  });
};
export const generateObjectsMask = ({
  fabricCanvas,
}: {
  fabricCanvas: fabric.Canvas;
}) => {
  const backgroundImage = fabricCanvas.backgroundImage;
  fabricCanvas.backgroundImage = undefined;
  fabricCanvas.renderAll();
  const objectsCanvas = fabricCanvas.toCanvasElement();
  if (backgroundImage) {
    fabricCanvas.setBackgroundImage(
      backgroundImage,
      fabricCanvas.renderAll.bind(fabricCanvas),
    );
  }
  return convertAlphaToMaskAndDilate({
    canvas: objectsCanvas,
    dilationWidth: 3,
  });
};
