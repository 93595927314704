import { Link, useNavigate } from "react-router-dom";
import { Button } from "../../components/Button/Button.tsx";
import { Icon } from "../../components/Icon/Icon.tsx";
import { Image } from "../../components/Image/Image.tsx";
import { BOARDS, CREATE_WORKSPACE } from "../../routes.ts";
import { upgradeDialogStore } from "../../singletons/UpgradeDialog/upgradeDialogStore.ts";
import { useCreateBoard } from "../Homepage/hooks/useCreateBoard.ts";
import type { Style } from "../types.ts";

export const StyleDescription = ({ style }: { style: Style }) => {
  const navigate = useNavigate();
  const { createBoard } = useCreateBoard({
    onBoardCreationForbidden: () => upgradeDialogStore.openDialog(),
    onSuccess: (uuid) => navigate(`${BOARDS}/${uuid}/${CREATE_WORKSPACE}`),
  });

  return (
    <div className="flex-row h-full">
      <div className="flex-col w-[350px] gap-[80px] px-xl py-3xl">
        <Link to="/">
          <div className="hover:opacity-70 flex-row px-md h-3xl">
            <Icon name="ChevronLeft" size={20} />
            <div className="text-lg">Back</div>
          </div>
        </Link>
        <div className="flex-col px-md gap-xl">
          <div className="flex-col gap-lg">
            {style.training_images.length > 0 ? (
              <Image
                className="h-[128px] w-[128px] rounded-md"
                imageClassName="h-full w-full object-cover object-center"
                src={style.training_images[0].url}
              />
            ) : (
              <div className="flex-col-center h-[128px] w-[128px] rounded-md bg-pimento-blue">
                <Icon
                  size={70}
                  name="PimentoStar"
                  className="stroke-transparent fill-pimento-yellow"
                />
              </div>
            )}
            <div className="text-3xl font-semibold">{style.name}</div>
          </div>
          <Button
            className="w-fit"
            size="large"
            onClick={() => {
              createBoard({ styleUuid: style.uuid });
            }}
          >
            <div className="font-semibold text-white text-lg px-lg">
              Use in new project
            </div>
          </Button>
        </div>
      </div>
      <div className="flex-fill bg-gray-100 p-3xl overflow-y-auto">
        <div className="flex-row flex-wrap gap-md">
          {style.training_images.map((image, index) => (
            <Image
              key={index}
              className="w-[300px] aspect-square"
              imageClassName="h-full w-full object-cover object-center"
              src={image.url}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
