import { useAuth0 } from "@auth0/auth0-react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { BaseButton } from "../../../components/Button/BaseButton.tsx";
import { ClickableIcon } from "../../../components/Icon/ClickableIcon.tsx";
import { Icon } from "../../../components/Icon/Icon.tsx";
import { Popover } from "../../../components/Popover/Popover.tsx";
import { useUser } from "../../../hooks/useUser.ts";
import { ADMIN } from "../../../routes.ts";
import { upgradeDialogStore } from "../../../singletons/UpgradeDialog/upgradeDialogStore.ts";
import { isUserAllowedTo } from "../../../types.ts";
import { getConfig } from "../../../utils/environment.ts";
import { trackEvent } from "../../../utils/trackEvent.ts";

export const Sidebar = () => {
  const { user } = useUser();
  const { user: auth0User, logout } = useAuth0();
  const [isPersonalMenuOpen, setIsPersonalMenuOpen] = useState(false);

  return (
    <div className="flex-col bg-gray-100 w-[72px] justify-between p-24">
      <Popover
        content={
          <div className="flex-col gap-xs p-md rounded bg-white border">
            <a
              href={`${getConfig({
                dev: "https://billing.stripe.com/p/login/test_fZe29W4rT3JC7gk5kk",
                staging:
                  "https://billing.stripe.com/p/login/test_fZe29W4rT3JC7gk5kk",
                prod: "https://billing.stripe.com/p/login/5kA8xe1F00UIeZO144",
              })}?prefilled_email=${auth0User?.email ?? "undefined"}`}
              target="_blank"
              className="flex-row items-center py-lg px-2xl gap-xl hover:bg-gray-100"
            >
              <Icon name="CreditCard" size={20} />
              Subscription
            </a>
            {user && isUserAllowedTo(user, "styles:copy") && (
              <Link
                to={ADMIN}
                className="flex-row items-center py-lg px-2xl gap-xl rounded hover:bg-gray-100"
              >
                <Icon name="Lock" size={20} />
                Admin
              </Link>
            )}
            <button
              type="button"
              className="flex-row items-center py-lg px-2xl gap-xl rounded hover:bg-gray-100"
              onClick={() => logout({ returnTo: window.location.origin })}
            >
              <Icon name="LogOut" size={20} />
              Log out
            </button>
          </div>
        }
        isOpen={isPersonalMenuOpen}
        onOpenChange={setIsPersonalMenuOpen}
        align="center"
        side="right"
        className="z-30 w-[110px] flex-row-center m-12 border-none text-xl font-medium"
      >
        {/* FIXME: Improve Icon component to be able to define in a single component the icon size and the parent size*/}
        <div className="flex-col-center w-full">
          <ClickableIcon
            name="UserRound"
            size={22}
            className="bg-black rounded-full"
            iconClassName="fill-white"
          />
        </div>
      </Popover>
      {user?.stripe_subscription_type === "free" && (
        <BaseButton
          onClick={() => {
            trackEvent("upgrade:display_popup", {
              reason: "upgrade_button_click",
            });
            upgradeDialogStore.openDialog({ isClosable: true });
          }}
          className="group flex-row-center w-full"
        >
          <div className="flex-col-center">
            <Icon
              name="Bolt"
              className="fill-pimento-yellow stroke-black group-hover:stroke-active-button-hover"
            />
            <div className="text-black text-md group-hover:text-active-button-hover">
              Upgrade
            </div>
          </div>
        </BaseButton>
      )}
    </div>
  );
};
