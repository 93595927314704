import classNames from "classnames";
import { useState } from "react";
import { Animation } from "../../components/Animation/Animation.tsx";
import { BaseButton } from "../../components/Button/BaseButton.tsx";
import { Button } from "../../components/Button/Button.tsx";
import { Dialog } from "../../components/Dialog/Dialog.tsx";
import { Icon } from "../../components/Icon/Icon.tsx";
import { trackEvent } from "../../utils/trackEvent.ts";
import { ErrorMessage } from "../../views/Board/Workspaces/components/ErrorMessage.tsx";
import { useCreateCheckoutUrl } from "../../views/Homepage/hooks/useCreateCheckoutUrl.ts";
import { upgradeDialogStore } from "./upgradeDialogStore.ts";

export const UpgradeDialog = () => {
  const { isDialogOpen, isClosable } = upgradeDialogStore.useState();

  return (
    <Dialog
      isOpen={isDialogOpen}
      onOpenChange={(open) => {
        if (open) {
          upgradeDialogStore.openDialog();
        } else {
          upgradeDialogStore.closeDialog();
        }
      }}
      clickOutsideAuthorized={isClosable}
      displayCloseButton={isClosable}
      escapeKeyDownAuthorized={isClosable}
      content={isDialogOpen && <DialogContent />}
    />
  );
};

const DialogContent = () => {
  const singlePanelDimensions = "h-[550px] w-[400px]";

  const [upgradeStep, setUpgradeStep] = useState<
    "upgrade" | "cancellingReasons"
  >("upgrade");

  return (
    <div className="flex-row">
      <div
        className={classNames("bg-black content-center", singlePanelDimensions)}
      >
        <Animation
          loadData={() => import("../../assets/boardInitLoading.json")}
          loop
          autoplay
        />
      </div>
      <div className={singlePanelDimensions}>
        {upgradeStep === "upgrade" ? (
          <UpgradeContent
            onFreeVersionClick={() => setUpgradeStep("cancellingReasons")}
          />
        ) : (
          <CancellingReasonsFormContent
            onPreviousClick={() => setUpgradeStep("upgrade")}
          />
        )}
      </div>
    </div>
  );
};

const UpgradeContent = ({
  onFreeVersionClick,
}: {
  onFreeVersionClick: () => void;
}) => {
  const { createCheckoutUrl, isCreateCheckoutUrlLoading } =
    useCreateCheckoutUrl();
  return (
    <div className="h-full flex-col px-2xl gap-3xl justify-center bg-white">
      <div className="flex-col text-gray-600">
        <span className="font-burg text-4xl">upgrade to pro</span>
        <span className="text-xl font-semibold">$39/month</span>
      </div>
      <div className="flex-col gap-md">
        {[
          "200 daily credits",
          "Unlimited custom models",
          "Access to all library models",
          "Dedicated customer support",
        ].map((item) => (
          <div key={item} className="flex-row gap-sm items-center">
            <Icon name="Check" size={12} className="stroke-gray-600" />
            <span className="text-lg text-gray-600">{item}</span>
          </div>
        ))}
      </div>
      <div className="flex-col gap-md items-center">
        <Button
          className="w-full"
          loading={isCreateCheckoutUrlLoading}
          onClick={() => {
            createCheckoutUrl({});
          }}
        >
          Start 7-day free trial
        </Button>
        <BaseButton
          className="w-full p-md flex-row-center rounded hover:bg-gray-200 active:bg-gray-100 font-semibold"
          onClick={onFreeVersionClick}
        >
          Continue with the free version
        </BaseButton>
      </div>
    </div>
  );
};

const CancellingReasonsFormContent = ({
  onPreviousClick,
}: {
  onPreviousClick: () => void;
}) => {
  const [selectedOptions, setSelectedOptions] = useState<FreeVersionFormType[]>(
    [],
  );
  const [textReason, setTextReason] = useState("");
  const [isError, setIsError] = useState(false);
  return (
    <div className="h-full flex-col px-2xl gap-3xl justify-center bg-white">
      <div className="flex-col text-gray-600">
        <span className="font-burg text-4xl">why not upgrading now?</span>
        <span className="text-lg text-gray-600">
          Help us improve our offer so it will suit you better in the future.
        </span>
      </div>
      <div className="flex-col gap-lg">
        <div className="flex-col gap-md">
          <div className="flex-row items-center flex-wrap gap-md">
            {CANCELLING_REASONS_FORM_OPTIONS.map((option) => (
              <BaseButton
                key={option}
                className={classNames(
                  "p-md rounded-lg border",
                  selectedOptions.includes(option)
                    ? "bg-gray-200 border-gray-600"
                    : "text-gray-500",
                )}
                onClick={() => {
                  if (selectedOptions.includes(option)) {
                    setSelectedOptions((options) =>
                      options.filter((op) => op !== option),
                    );
                  } else {
                    setSelectedOptions((options) => [...options, option]);
                  }
                }}
              >
                {formOptionsToLabel[option]}
              </BaseButton>
            ))}
          </div>
          {isError && <ErrorMessage text="Please select a reason" />}
        </div>
        <div className="flex-col gap-md">
          <div className="font-semibold">Anything else you want to share?</div>
          <textarea
            className="w-full resize-none h-[84px] rounded-sm p-md border placeholder:text-placeholder focus:border-gray-300 hover:border-gray-300"
            placeholder="Comments"
            value={textReason}
            onChange={(event) => {
              setTextReason(event.target.value);
            }}
            onKeyDown={(e) => {
              e.stopPropagation();
            }}
          />
        </div>
      </div>
      <div className="flex-col gap-md items-center">
        <Button
          className="w-full"
          onClick={() => {
            if (selectedOptions.length === 0) {
              setIsError(true);
            } else {
              setIsError(false);
              trackEvent("upgrade:continue-as-free", {
                selected_reasons: selectedOptions,
                text_reason: textReason,
              });
              upgradeDialogStore.closeDialog();
            }
          }}
        >
          Send feedback and close
        </Button>
        <BaseButton
          className="w-full p-md flex-row-center rounded hover:bg-gray-200 active:bg-gray-100 font-semibold"
          onClick={onPreviousClick}
        >
          I’ve changed my mind
        </BaseButton>
      </div>
    </div>
  );
};

const CANCELLING_REASONS_FORM_OPTIONS = [
  "too-expensive",
  "still-testing",
  "quality",
  "other",
] as const;

const formOptionsToLabel = {
  "too-expensive": "Too expensive",
  "still-testing": "I’m still testing",
  quality: "Output quality is not good enough",
  other: "Other",
} as const;

type FreeVersionFormType = (typeof CANCELLING_REASONS_FORM_OPTIONS)[number];
