import { useNavigate } from "react-router-dom";
import { Button } from "../../../components/Button/Button.tsx";
import { RadialProgress } from "../../../components/RadialProgress/RadialProgress.tsx";
import { BOARDS, CREATE_WORKSPACE } from "../../../routes.ts";
import { upgradeDialogStore } from "../../../singletons/UpgradeDialog/upgradeDialogStore.ts";
import { useBoards } from "../hooks/useBoards.ts";
import { useCreateBoard } from "../hooks/useCreateBoard.ts";

export const NewBoardOrUpgrade = () => {
  const navigate = useNavigate();
  const { createBoard, isCreateBoardLoading } = useCreateBoard({
    onBoardCreationForbidden: () => upgradeDialogStore.openDialog(),
    onSuccess: (uuid) => navigate(`${BOARDS}/${uuid}/${CREATE_WORKSPACE}`),
  });

  const { remainingUserBoards, maxAmountOfUserBoards } = useBoards();

  return (
    <Button
      className="group"
      onClick={() => createBoard({})}
      loading={isCreateBoardLoading}
    >
      <div className="flex-row items-center gap-md text-inherit">
        New project
        {/*  We arbitrarly show the remaining amount of user boards in case of less than 100*/}
        {remainingUserBoards < 100 && (
          <RadialProgress
            progress={remainingUserBoards}
            minProgress={0}
            maxProgress={maxAmountOfUserBoards}
            counterClockwise
            backgroundClassName="group-hover:bg-gray-600 bg-gray-700"
          />
        )}
      </div>
    </Button>
  );
};
