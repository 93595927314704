import classNames from "classnames";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { BaseButton } from "../../../components/Button/BaseButton.tsx";
import { Carousel } from "../../../components/Carousel/Carousel.tsx";
import { Icon } from "../../../components/Icon/Icon.tsx";
import { useOnMount } from "../../../hooks/useOnMount.ts";
import { useUser } from "../../../hooks/useUser.ts";
import { BOARDS, CREATE_WORKSPACE } from "../../../routes.ts";
import { upgradeDialogStore } from "../../../singletons/UpgradeDialog/upgradeDialogStore.ts";
import { StylePicker } from "../../components/StylePicker/StylePicker.tsx";
import { useCreateBoard } from "../hooks/useCreateBoard.ts";
import { useCreateStyle } from "../hooks/useCreateStyle.ts";
import { useStyles } from "../hooks/useStyles.ts";
import type { Style } from "../types.ts";
import { CUSTOM_TAG, POPULAR_TAG } from "./constants.ts";
import { NewStyleTileOrUpgrade } from "./NewStyleOrUpgrade.tsx";
import { NewStyleTile } from "./NewStyleTile.tsx";
import { StyleTile } from "./StyleTile.tsx";

export const StyleSection = () => {
  const { libraryStyles, userStyles, libraryStylesCountByTags } = useStyles();

  const [selectedTag, setSelectedTag] = useState<string | undefined>(
    POPULAR_TAG,
  );
  const [isStylePickerOpen, setIsStylePickerOpen] = useState(false);
  const navigate = useNavigate();

  const onStyleClick = (style: Style) => {
    navigate(`/styles/${style.uuid}`);
  };
  const { createBoard } = useCreateBoard({
    onBoardCreationForbidden: () => upgradeDialogStore.openDialog(),
    onSuccess: (uuid) => navigate(`${BOARDS}/${uuid}/${CREATE_WORKSPACE}`),
  });

  const { createStyle, isCreateStyleLoading } = useCreateStyle();

  const { user } = useUser();

  return (
    <>
      <div className="flex-col gap-lg">
        <div className="px-3xl flex-row items-center justify-between">
          <div className="flex-row gap-xl items-center">
            <div className="text-2xl font-semibold">Model library</div>
            {libraryStyles !== undefined &&
              userStyles !== undefined &&
              libraryStylesCountByTags !== undefined && (
                <StyleTabs
                  libraryStyles={libraryStyles}
                  userStyles={userStyles}
                  libraryStylesCountByTags={libraryStylesCountByTags}
                  selectedTag={selectedTag}
                  onSelectedTagUpdate={setSelectedTag}
                />
              )}
          </div>
          <div className="flex-row gap-xl">
            <button
              type="button"
              className="font-semibold hover:opacity-70"
              onClick={() => setIsStylePickerOpen(true)}
            >
              See all
            </button>
            <div className="flex-row gap-lg">
              <NewStyleTileOrUpgrade />
            </div>
          </div>
        </div>
        {/* FIXME: Can be split in two different sections (custom / library) */}
        {libraryStyles && (
          <div className="w-full">
            {userStyles !== undefined &&
            userStyles.length === 0 &&
            selectedTag === CUSTOM_TAG ? (
              <div className="px-3xl">
                <BaseButton
                  loading={isCreateStyleLoading}
                  onClick={createStyle}
                  className="w-full h-[220px] bg-info-100 border-lg rounded-sm group hover:border-pimento-blue hover:bg-info-200 flex-col-center gap-lg"
                >
                  <Icon
                    name="Palette"
                    size={20}
                    className="stroke-pimento-blue"
                  />
                  <span className="text-xl font-semibold text-pimento-blue">
                    Generate endless content in your style
                  </span>
                  <div className="h-3xl pl-md pr-xl bg-white rounded-sm border flex-row items-center gap-lg stroke-gray-600 group-hover:opacity-70 group-active:bg-gray-600 group-active:text-white group-active:stroke-white">
                    <Icon name="Plus" className="stroke-inherit" />
                    Create your first model
                  </div>
                </BaseButton>
              </div>
            ) : (
              <div className="pl-3xl w-full flex-row">
                <div className="flex-row gap-md">
                  <NewStyleTile />
                  <div className="border-l h-[220px]" />
                </div>
                <div className="flex-fill">
                  <Carousel
                    items={[
                      ...((selectedTag === CUSTOM_TAG ||
                        selectedTag === undefined) &&
                      userStyles
                        ? userStyles.map((userStyle) => (
                            <StyleTile
                              key={userStyle.uuid}
                              style={userStyle}
                              onClick={() => onStyleClick(userStyle)}
                            />
                          ))
                        : []),
                      ...libraryStyles
                        .filter((style) =>
                          selectedTag ? style.tags.includes(selectedTag) : true,
                        )
                        .let((filteredStyles) =>
                          filteredStyles.map((libraryStyle) => (
                            <StyleTile
                              key={libraryStyle.uuid}
                              style={libraryStyle}
                              onClick={() => onStyleClick(libraryStyle)}
                              isEditable={user?.permissions.includes(
                                "styles:update-curated-styles",
                              )}
                            />
                          )),
                        ),
                    ]}
                    leftButtonClassName="top-[86px] left-3xl"
                    rightButtonClassName="top-[86px] right-3xl"
                    className="pl-md pr-3xl gap-md"
                  />
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      <StylePicker
        dialogOpen={isStylePickerOpen}
        onDialogChange={(open) => setIsStylePickerOpen(open)}
        onStyleSelect={(styleUuid) => {
          createBoard({ styleUuid });
        }}
        initTag={selectedTag}
        initStyleCategory={
          selectedTag === undefined
            ? "all"
            : selectedTag === CUSTOM_TAG
            ? "user"
            : "library"
        }
      />
    </>
  );
};

const StyleTabs = ({
  libraryStyles,
  userStyles,
  libraryStylesCountByTags,
  selectedTag,
  onSelectedTagUpdate,
}: {
  libraryStyles: Style[];
  userStyles: Style[];
  libraryStylesCountByTags: { [_: string]: number | undefined };
  selectedTag: string | undefined;
  onSelectedTagUpdate: (tag: string | undefined) => void;
}) => {
  const libraryStylesCount = libraryStyles.length;

  useOnMount(() => {
    if (userStyles.length > 0) onSelectedTagUpdate(CUSTOM_TAG);
  });

  return (
    <div className="flex-row gap-lg text-lg font-semibold">
      {Object.keys(libraryStylesCountByTags).includes(POPULAR_TAG) && (
        <BaseButton onClick={() => onSelectedTagUpdate(POPULAR_TAG)}>
          <span
            className={classNames(
              POPULAR_TAG === selectedTag
                ? "text-pimento-blue underline underline-offset-4 decoration-[1.5px]"
                : "text-gray-400",
            )}
          >
            {POPULAR_TAG} ({libraryStylesCountByTags[POPULAR_TAG]})
          </span>
        </BaseButton>
      )}
      <BaseButton onClick={() => onSelectedTagUpdate(CUSTOM_TAG)}>
        <span
          className={classNames(
            CUSTOM_TAG === selectedTag
              ? "text-pimento-blue underline underline-offset-4 decoration-[1.5px]"
              : "text-gray-400",
          )}
        >
          My Models ({userStyles.length})
        </span>
      </BaseButton>
      <BaseButton onClick={() => onSelectedTagUpdate(undefined)}>
        <span
          className={classNames(
            selectedTag === undefined
              ? "text-pimento-blue underline underline-offset-4 decoration-[1.5px]"
              : "text-gray-400",
          )}
        >
          All ({libraryStylesCount + userStyles.length})
        </span>
      </BaseButton>
      {Object.keys(libraryStylesCountByTags)
        .filter((tagName) => tagName !== POPULAR_TAG)
        .sort()
        .map((tagName) => (
          <BaseButton
            key={tagName}
            onClick={() => onSelectedTagUpdate(tagName)}
          >
            <span
              className={classNames(
                tagName === selectedTag
                  ? "text-pimento-blue underline underline-offset-4 decoration-[1.5px]"
                  : "text-gray-400",
              )}
            >
              {tagName} ({libraryStylesCountByTags[tagName]})
            </span>
          </BaseButton>
        ))}
    </div>
  );
};
